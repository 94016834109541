<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="!isEdit"
                :formItems="showPopForms"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @machineChange="machineChange(1)" 
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="pageChange($event)"
            @modify="detail($event, 'modify')"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { isValid, machineChangeList, machineChangeModify, machineChangeAdd, machineChangeDetail } from '../../utils/api'
import { assginDataToArray, formatTime, geneIndex, getValueFromObjArr } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '修改', eventName: 'modify'},
                //  {text: '审批', eventName: 'modifyStatus'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '记录编号', dataIndex: 'id', key: 'id'},
                 {title: '操作类型', dataIndex: 'taskTypeText', key: 'taskTypeText'},
                 {title: '旧设备编号', dataIndex: 'oldDeviceNo', key: 'oldDeviceNo'},
                 {title: '新设备编号', dataIndex: 'newDeviceNo', key: 'newDeviceNo'},
                 {title: '操作人编号', dataIndex: 'operatorId', key: 'operatorId'},
                 {title: '创建时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '最近修改', dataIndex: 'updateTime', key: 'updateTime'},
                 {title: '状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', key: 'oldDeviceNo', value: '', label: '旧设备编号'},
                 {type: 'input', key: 'newDeviceNo', value: '', label: '新设备编号'},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
                 {type: 'button', eventName: 'machineChange', label: '新增退换'},
             ],
             popForms: [
                 {label: '记录编号', prop: 'id', value: '', type: 'input'},
                 {label: '旧设备编号', prop: 'oldDeviceNo', value: '', type: 'input'},
                 {label: '新设备编号', prop: 'newDeviceNo', value: '', type: 'input'},
                 {label: '操作人编号', prop: 'operatorId', value: '', type: 'input'},
                 {label: '代理编号', prop: 'agentId', value: '', type: 'input'},
                 {label: '创建时间', prop: 'createTime', value: '', type: 'input'},
                 {label: '最近修改', prop: 'updateTime', value: '', type: 'input'},
                 {label: '记录状态', prop: 'statusText', value: '', type: 'input'},
            ],
            eidtForms:[
                {type: 'selector', prop: 'taskType', key: 'value', options: [{labelName: '更换设备', value: 0}, {labelName: '设备退机', value: 1}], label: '任务类型', value: ''},
                 {label: '旧设备编号', prop: 'oldDeviceNo', value: '', type: 'input'},
                 {label: '新设备编号', prop: 'newDeviceNo', value: '', type: 'input', hiddenTaskType: 1},
            ],
            modifyEidtForms:[
                 {type: 'selector', prop: 'taskType', key: 'value', options: [{labelName: '更换设备', value: 0}, {labelName: '设备退机', value: 1}], label: '任务类型', value: ''},
                 {label: '旧设备编号', prop: 'oldDeviceNo', value: '', type: 'input'},
                 {label: '新设备编号', prop: 'newDeviceNo', value: '', type: 'input', hiddenTaskType: 1},
                 {type: 'selector', prop: 'status', key: 'value', options: [{labelName: '初始化', value: 0}, {labelName: '已确认', value: 1}, {labelName: '更换成功', value: 2}, {labelName: '更换失败', value: 3}], label: '任务状态', value: ''},
            ],
            popType: 0, // 0 详情 1 新增 2 修改
            isEdit: false,
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: false,
             status: 0,
             detail: {},
             showCheckDialog: false,
             statusList: [
                 {label: '待确认', value: 0},
                 {label: '待处理', value: 1},
                 {label: '更换成功', value: 2},
                 {label: '更换失败', value: 3},
                 {label: '已取消', value: 4}
             ]
        })
        const taskTypeOptions = [
            [0, '更换设备'],
            [1, '退机']
        ]
        const statusOptions = [
            [0, '待确认'],
            [1, '待处理'],
            [2, '处理成功'],
            [3, '处理失败'],
            [4, '取消']
        ]
        const showPopForms = computed(() => {
            return state.popType == 0 ? state.popForms :
            state.popType == 1 ? state.eidtForms : state.modifyEidtForms

            // return state.isEdit ? 
            // state.eidtForms.filter(ele =>  !state.eidtForms[0].hiddenTaskType || ele.hiddenTaskType != state.eidtForms[0].hiddenTaskType) 
            // : state.popForms
        })
        const readOnly = computed(() => {
            return state.popType == 0
        })
        function getStatusText(value) {
            return state.statusList.filter(ele => ele.value == value)?.[0].label || ''
        }
        async function  loadData(current) {
            try {
                state.currentPage = current || state.currentPage
                state.loading = true
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    oldDeviceNo:  state.formItems[0].value,
                    newDeviceNo: state.formItems[1].value,
                }
                const result = await machineChangeList(params)
                state.loading = false
                if(isValid(result)) {
                    state.tableList = geneIndex(result.data.list, state.pageSize, state.currentPage)
                    state.total = result.data.total
                    state.tableList.forEach((ele) => {
                        ele.createTime = formatTime(ele.createTime)
                        ele.updateTime = formatTime(ele.updateTime)
                        ele.statusText = getValueFromObjArr(statusOptions, ele.status)
                        ele.taskTypeText = getValueFromObjArr(taskTypeOptions, ele.taskType)
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        function machineChange(typeValue) {
            state.isEdit = true 
            state.popType = typeValue
            state.detail = {}
            state.popForms.forEach(ele => ele.value = '')
            state.eidtForms.forEach(ele => ele.value = '')
            state.showDialog = true 
        }
        async function detail(event, type="detail") {
            try {
                state.title = '记录详情'
                state.popType = type == 'modify' ? 3 : 0
                const result = await machineChangeDetail(event.id)
                if (isValid(result)) {
                    const ele = result.data
                    ele.createTime = formatTime(ele.createTime)
                    ele.updateTime = formatTime(ele.formatTime)
                    state.detail = result.data
                    ele.statusText = getStatusText(ele.status)
                    if (type == 'detail') {
                        state.popForms = assginDataToArray(result.data, state.popForms)
                    } else {
                        state.eidtForms = assginDataToArray(result.data, state.eidtForms)
                    }
                    state.isEdit = type == 'modify'
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }

        function arrayToObject(arr) {
            const obj = {}
            arr.forEach(ele => {
                if (ele.prop) {
                    obj[ele.prop] = ele.value
                }
            })
            return obj
        }
       
        async function closeDialog() {
            try {
                if (state.isEdit) {
                    const items = state.popType == 1 ? state.eidtForms : state.modifyEidtForms
                    const params = arrayToObject(items)
                    const id = state.detail.id
                    if (id) {
                        params.id = id
                    }
                    const result = id ?  await machineChangeModify(params) : await machineChangeAdd(params)
                    if (isValid(result)) {
                        const tip = (id ? '修改' : '添加') + '成功'
                        message.success(tip)
                        loadData()
                        state.showDialog = false
                    }
                } else {
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
        function pageChange(event) {
            state.currentPage = event
            loadData()
        }
        
        return {
            ...toRefs(state),
            showPopForms,
            detail,
            loadData,
            closeDialog,
            pageChange,
            machineChange
        }
    },
}
</script>
<style lang='scss' scoped>
</style>